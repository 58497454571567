#embedHolder {
    text-align: left;
    margin: auto;
    padding-left: 50px;
    padding-right: 50px;
    transition: height 1s ease;
    color: whitesmoke;
    user-select: none;
}

#imageContainer {
    float: left;
    padding-right: 10px;
    transition-duration: 1s;
    transform-origin: top right;
    margin: auto;
    position: relative;
}

#textContainer {
    transition: all 1s ease;
    position: relative;
    overflow: hidden;
    user-select: none;
}

a {
  color: white;
}
